import consumer from "./consumer"
import $ from "jquery"

let data_slug = $(".slug").attr('data-slug')

function isEmpty(value) {
  return (value == null || value.length === 0);
}

function get_song_or_speaker(data) {
  if (isEmpty(data.noar.song)) {
    return data.noar.speaker_name
  }
  return data.noar.song
}

function get_artist_or_program(data) {
  if (isEmpty(data.noar.artist)) {
    return data.noar.program_name
  }
  return data.noar.artist
}

function get_itunes_link(data) {
  if (isEmpty(data.noar.itunes)) {
    return "https://music.apple.com"
  }
  return data.noar.itunes
}

function get_image_link(data) {
  if (isEmpty(data.noar.artwork)) {
    return data.noar.speaker_picture ?? data.noar.program_image
  }
  return data.noar.artwork
}

function get_itunes_css_display(data) {
  if (isEmpty(data.noar.artwork)) {
    return "none"
  }
  return "block"
}

consumer.subscriptions.create({
  channel: "NoarChannel",
  slug: data_slug
}, {
  connected() {
    console.log('Conectou no NoarChannel: ' + data_slug);
  },

  disconnected() {
    console.log('Desconectou do NoarChannel: ' + data_slug);
  },

  received(data) {
    console.log('Recebendo dados do NoarChannel: ' + data_slug)

    $(".speaker").text(data.noar.speaker_name)
    $(".program").text(data.noar.program_name)

    $(".song-name").text(get_song_or_speaker(data))
    $(".artist-name").text(get_artist_or_program(data))

    $("#itunes-link").attr("href", get_itunes_link(data))

    let image = get_image_link(data)
    $(".album").attr("src", image)
    $(".photo-album").attr("src", image)
    $(".photo-album-small").attr("src", image)

    $(".itunes").css("display", get_itunes_css_display(data))
  }
});
